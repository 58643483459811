






























































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import debounce from 'lodash/debounce';
import { GaponeService } from '@/services/GaponeService';

@Component({
    components: {
        vSelect,
    }
})
export default class UserConfig extends Vue {
    currentPage: number = 1;
    value: string = "123";
    text: string = "Hello tuyến đường";
    users: any = [];
    totalPages = 0;
    pageSize = 10;
    popupActive: Boolean = false;
    moreSettingPopupActive: Boolean = false;
    popupInventoryActive: boolean = false;
    keyword: string = '';
    selected: any = [];
    private gaponeService: GaponeService;
    currentUser: any = {};
    backgroundLoading: string = 'primary';
    colorLoading: string = '#fff';
    totalRows: number = 0;
    branches: any = [];
    inventories: any = [];
    saleChannels: any = [];
    roles: any = [];
    debounce: any = null;
    selectedChannel: string | null = null;

    constructor() {
        super();
        this.gaponeService = new GaponeService();
    }

    beforeMount() {
    }

    mounted() {
        this.search();
    }

    async setChannel(channel: string) {
        this.selectedChannel = channel;
    }

    //
    // async Test(id: string)
    // {
    //     const result = await this.userConfigService.getCustomerConfig(id);
    //
    //     if (result.code > 0) {
    //         this.roles = result.items;
    //     }
    // }

    @Watch('keyword')
    onKeyWordInputChange(value: string, oldValue: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.currentPage = 1;
        this.debounce = debounce(() => this.search(), 500);
        this.debounce();
    }

    @Watch('currentPage')
    onCurrentPageChange(value: string, oldValue: string) {
        this.search();
    }

    openPopup(item: any, field: string) {
        if (field == "branch") {
            this.popupActive = true;
        } else {
            this.popupInventoryActive = true;
        }

        this.currentUser = item;
    }

    getDetail(item: any) {
        console.log(item)
    }

    async save_changes(item: any, field: string) {
        // let newObj = JSON.parse(JSON.stringify(item));
        //
        // const salesChannelInfo = find(this.saleChannels, (channel: any) => {
        //     return channel.id === item.channelId;
        // })
        //
        // if (salesChannelInfo) {
        //     newObj.channelName = salesChannelInfo.name;
        // }
        //
        // if (field === "isChannelLeader") {
        //     newObj.isChannelLeader = !newObj.isChannelLeader;
        // } else if (field === "isSaleAdmin") {
        //     newObj.isSaleAdmin = !newObj.isSaleAdmin;
        // } else if (field === "logisticManager") {
        //     newObj.logisticManager = !newObj.logisticManager;
        // } else if (field === "receiveSONotify") {
        //     newObj.receiveSONotify = !newObj.receiveSONotify;
        // }

        // const result = await this.userConfigService.update(newObj);
        //
        // if (result.code > 0) {
        //     this.$vs.notify({
        //         title: "Thông báo",
        //         text: "Lưu thành công",
        //         color: 'primary',
        //         iconPack: 'feather',
        //         icon: 'icon-info'
        //     });
        // }
    }

    async saveMore(btnId: string) {
        // this.$vs.loading({
        //     background: this.backgroundLoading,
        //     color: this.colorLoading,
        //     container: "#" + btnId,
        //     scale: 0.45
        // })
        //
        // const result = await this.userConfigService.update(this.currentUser);
        //
        // setTimeout(() => {
        //     this.$vs.loading.close("#" + btnId + " > .con-vs-loading")
        // }, 500);
        //
        // if (result.code > 0) {
        //     this.$vs.notify({
        //         title: "Thông báo",
        //         text: "Lưu thành công",
        //         color: 'primary',
        //         iconPack: 'feather',
        //         icon: 'icon-info'
        //     });
        //
        //     if (btnId == "btnSaveBranchs") {
        //         this.popupActive = false;
        //     } else if (btnId == "button-with-loading") {
        //         this.moreSettingPopupActive = false;
        //         setTimeout(() => {
        //             this.$vs.loading.close("#button-with-loading > .con-vs-loading")
        //         }, 500);
        //     } else if (btnId == "btnSaveinventories") {
        //         this.popupInventoryActive = false;
        //         setTimeout(() => {
        //             this.$vs.loading.close("#btnSaveinventories > .con-vs-loading")
        //         }, 500);
        //     }
        // }
    }

    openLoading() {
        this.$vs.loading()
    }

    beginSearch() {
        if (this.keyword == undefined || this.keyword.length < 2) {
            return;
        }

        this.search();
    }

    private async search() {
        this.openLoading();
        const result = await this.gaponeService.search(this.keyword, this.selectedChannel, this.currentPage);
        if (result.code > 0) {
            this.users = result.items;
            this.setTotalPage(result.totalRows);
        }
        this.$vs.loading.close()
    }

    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;

        if (totalRows == 0) {
            this.totalPages = 0;
        }
        if (totalRows <= this.pageSize) {
            this.totalPages = 1;
        }
        this.totalPages = Math.ceil((totalRows / this.pageSize));
    }
}
