import axios from 'axios';
import {SearchResult} from "@/models/SearchResult";

export class MenuView {
    apps: Array<AppView>;
    pages: Array<PageView>;
}

export class AppView {
    header: string;
    icon: string;
    i18n: string;
    items: Array<ModuleView>;
}

export class ModuleView {
    url: string;
    name: string;
    slug: string;
    icon: string;
    isDisabled: boolean = false;
    featherIcon: boolean = true;
    submenu: Array<PageView>;
}

export class PageView {
    url: string;
    name: string;
    slug: string;
    icon: string;
    add: boolean;
    delete: boolean;
    update: boolean;
    approve: boolean;
    upload: boolean;
    download: boolean;
    print: boolean;
    isExternal: boolean;
    isBookmarked: boolean;
}

export class GaponeService {
    url = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/gapones`;

    search(keyword, channel, page):
        Promise<SearchResult> {
        return axios.get(this.url, {
            params: {
                keyword: keyword,
                channel: channel ? channel : '',
                page: page
            }
        });
    }
}
